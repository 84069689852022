import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  AppBar,
  List,
  makeStyles,
  Toolbar,
  Container,
  IconButton,
  Collapse,
  Menu,
  MenuItem,
  SvgIcon,
} from "@material-ui/core";

import { THEME_SETTING } from "common/constants";
import { useMatchesSize } from "common/hooks";
import { useTranslation } from "react-i18next";

import ListItemLink from "components/ListItemLink";
import ReorderIcon from "@material-ui/icons/Reorder";
import ElevationScroll from "components/ElevationScroll";
import Search from "components/Search";
import { languagesLabel } from "common/i18n";
import Logo from "assets/images/vietherb.png";
import Download from "containers/Download";

//-------------------------------------
const Header = React.memo(() => {
  const matches = useMatchesSize("sm", "down");
  const classes = useStyles();
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <Fragment>
      <ElevationScroll>
        <AppBar
          position="static"
          classes={{
            root: classes.appBarFixed,
          }}
        >
          <Container maxWidth="xl" disableGutters={true}>
            <Toolbar className={classes.toolbar}>
              {matches && (
                <img
                  src={Logo}
                  alt="Vietherb Logo"
                  onClick={() => history.push("")}
                  className={classes.logo}
                />
              )}
              <div className={classes.menu}>
                {!matches && <Search />}

                <IconButton
                  color="primary"
                  aria-label="language"
                  component="span"
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <SvgIcon
                    fontSize="large"
                    component={
                      languagesLabel.find(
                        (language) => language.code === i18n.language
                      )?.flag.svg
                    }
                    viewBox={
                      languagesLabel.find(
                        (language) => language.code === i18n.language
                      )?.flag.viewBox
                    }
                  />
                </IconButton>

                <Menu
                  id="language-menu"
                  classes={{
                    list: classes.language,
                  }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {languagesLabel.map((language) => (
                    <MenuItem
                      key={language.code}
                      component="a"
                      data-no-link="true"
                      selected={i18n.language === language.code}
                      onClick={() => {
                        i18n.changeLanguage(language.code);
                        setAnchorEl(null);
                      }}
                      lang={language.code}
                      hrefLang={language.code}
                    >
                      <SvgIcon
                        component={language.flag.svg}
                        viewBox={language.flag.viewBox}
                      />
                    </MenuItem>
                  ))}
                </Menu>

                {matches && (
                  <IconButton onClick={() => setShowMenu(!showMenu)}>
                    <ReorderIcon fontSize="large" className={classes.icon} />
                  </IconButton>
                )}
              </div>
            </Toolbar>
            <Collapse in={showMenu} timeout="auto" unmountOnExit>
              <List component="div" className={classes.mobileMenu}>
                <ListItemLink
                  title={t("home")}
                  to=""
                  onClick={() => setShowMenu(false)}
                />
                <ListItemLink
                  title={t("species")}
                  to="/species"
                  onClick={() => setShowMenu(false)}
                />
                <ListItemLink
                  title={t("metabolites")}
                  to="/metabolites"
                  onClick={() => setShowMenu(false)}
                />
                <ListItemLink
                  title={t("online_prediction")}
                  to="/online-prediction"
                  button
                  onClick={() => {
                    alert("Coming soon!");
                    setShowMenu(false);
                  }}
                />
                <ListItemLink
                  title={t("download")}
                  to="/download"
                  button
                  onClick={() => {
                    setShowMenu(false);
                    setOpen(true);
                  }}
                />
                <ListItemLink
                  title={t("about_us")}
                  to="/about"
                  onClick={() => setShowMenu(false)}
                />
                <ListItemLink
                  title={t("contact")}
                  to="/contact"
                  onClick={() => setShowMenu(false)}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {matches && <Search />}
                </div>
              </List>
            </Collapse>

            <Download open={open} setOpen={setOpen} />
          </Container>
        </AppBar>
      </ElevationScroll>
    </Fragment>
  );
});

const useStyles = makeStyles((theme) => ({
  appBarFixed: {
    height: THEME_SETTING.APP_BAR_HEIGHT,
    backgroundColor: theme.palette.vietherb,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: THEME_SETTING.APP_BAR_HEIGHT,
    padding: "0 64px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  menu: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  icon: {},
  extraAppBar: {
    height: THEME_SETTING.APP_BAR_HEIGHT,
  },
  logo: {
    width: 150,
    cursor: "pointer",
  },
  mobileMenu: {
    background: theme.palette.vietherb,
    width: "100%",
  },
  language: {
    padding: 0,
  },
}));

export default Header;
