import { lazy } from "react";
//-------------------------------------
const Species = lazy(() => import("containers/Species"));
const SpeciesDetail = lazy(() => import("containers/Species/Detail/index"));
const FamiliaDetail = lazy(() => import("containers/Species/Familia"));
const GenusDetail = lazy(() => import("containers/Species/Genus"));
const TherapeuticDetail = lazy(() => import("containers/Species/Therapeutic"));
const Metabolite = lazy(() => import("containers/Metabolite"));
const MetaboliteDetail = lazy(() => import("containers/Metabolite/Detail"));
const About = lazy(() => import("containers/About"));
const Contact = lazy(() => import("containers/Contact"));

const routes = [
  {
    path: "/species",
    component: Species,
  },
  {
    path: "/species/:id",
    component: SpeciesDetail,
  },
  {
    path: "/familia/:id",
    component: FamiliaDetail,
  },
  {
    path: "/genus/:id",
    component: GenusDetail,
  },
  {
    path: "/therapeutic-effects/:id",
    component: TherapeuticDetail,
  },
  {
    path: "/metabolites",
    component: Metabolite,
  },
  {
    path: "/metabolites/:id",
    component: MetaboliteDetail,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/contact",
    component: Contact,
  },
];

export default routes;
