import React, { Suspense, lazy } from "react";

import routes from "./routes";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import UserLayout from "containers/NewLayout";
// import PredictionLayout from "containers/OnlinePrediction/PredictionLayout";

import Loading from "components/Loading";
import ScrollToTop from "routes/ScrollToTop";

const NotFound = lazy(() => import("containers/NotFound"));
const Index = lazy(() => import("containers/NewHome"));
// const Prediction = lazy(() => import("containers/OnlinePrediction/Prediction"));
// const Prediction1 = lazy(() =>
//   import("containers/OnlinePrediction/Prediction1")
// );

function MainRouter() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/">
          <UserLayout>
            <Route exact strict path="/">
              <Suspense fallback={<Loading />}>
                <Index />
              </Suspense>
            </Route>

            {/* dashboard router */}
            {/* <Route path="/online-prediction">
              <PredictionLayout>
                <Route exact strict path="/online-prediction">
                  <Suspense fallback={<Loading />}>
                    <Prediction />
                  </Suspense>
                </Route>

                <Route exact strict path="/online-prediction/input1">
                  <Suspense fallback={<Loading />}>
                    <Prediction1 />
                  </Suspense>
                </Route>
              </PredictionLayout>
            </Route> */}

            {routes.map((route, i) => {
              if (!route.protected) {
                return (
                  <Route key={i} exact strict path={route.path}>
                    <Suspense fallback={<Loading />}>
                      <route.component params={route.params} />
                    </Suspense>
                  </Route>
                );
              } else {
                return (
                  <ProtectedRoute key={i} exact strict path={route.path}>
                    <Suspense fallback={<Loading />}>
                      <route.component params={route.params} />
                    </Suspense>
                  </ProtectedRoute>
                );
              }
            })}
          </UserLayout>
        </Route>

        <Route exact strict path="*">
          <Suspense fallback={<Loading />}>
            <NotFound />
          </Suspense>
        </Route>
      </Switch>
    </Router>
  );
}

export default MainRouter;
