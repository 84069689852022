import React from "react";
import { CircularProgress } from "@material-ui/core";

export default function BackdropLoading({ spinnerProps }) {
  return (
    <div
      style={{
        minHeight: 500,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
}
