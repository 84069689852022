import { memo } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { useRouteMatch } from "react-router";

const Footer = () => {
  const classes = useStyles();

  const match = useRouteMatch("/online-prediction");

  return match ? null : (
    <div className={classes.container}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.contact}
      >
        <Grid item>
          <Typography
            align="center"
            variant="body2"
            className={classes.contactText}
          >
            HCMC,VN 24218
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            align="center"
            variant="body2"
            className={classes.contactText}
          >
            Ho Chi Minh International University
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            align="center"
            variant="body2"
            className={classes.contactText}
          >
            ly.le@hcmiu.edu.vn
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.copyright}>
        <Typography variant="overline">
          © 2016 VIETHERB.COM.VN. ALL RIGHTS RESERVED.
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    background: "#FFF",
  },
  contact: {
    padding: "20px 0",
    background: "#F3F2F2",
  },
  contactText: {
    cursor: "pointer",
    margin: "10px 20px",
    fontWeight: 600,
    color: "#686868",
  },
  copyright: {
    textAlign: "center",
    padding: 15,
    background: "#EDEDED",
    color: "#aaa",
  },
}));

export default memo(Footer);
