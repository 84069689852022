/*eslint-disable*/
import React from "react";
import classNames from "classnames";

import {
  NavLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";

import styles from "./styles/sidebarStyle";
import Download from "containers/Download";

// icon svg
import { ReactComponent as Leaf } from "assets/images/svg/leaves.svg";
import { ReactComponent as Chemical } from "assets/images/svg/chemical.svg";
import { ReactComponent as Healing } from "assets/images/svg/healing.svg";

const useStyles = makeStyles(styles);
const activeColor = "activeColor";
export default function Sidebar(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const activeRoute = (routeName) => {
    return location.pathname === routeName;
  };
  const { logo, image, logoText, routes } = props;
  const links = () => (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        let activePro = " ";
        let listItemClasses;

        listItemClasses = classNames({
          [" " + classes[activeColor]]: activeRoute(prop.path),
        });

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.path),
        });

        return (
          <NavLink
            to={prop.path}
            className={classes.item}
            activeClassName="active"
            key={key}
            onClick={() => {
              if (prop.name === "download") setOpen(true);
              else if (prop.name === "online_prediction") alert("Coming soon!");
            }}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                >
                  {prop.icon}
                </Icon>
              ) : (
                prop.icon(classNames(classes.itemIcon, whiteFontClasses))
              )}
              <ListItemText
                primary={t(prop.name)}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  const brand = () => (
    <div className={classes.logo}>
      <img src={logo} alt="Vietherb Logo" onClick={() => history.push("/")} />
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {brand()}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links()}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand()}
          <div className={classes.sidebarWrapper}>{links()}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Download open={open} setOpen={setOpen} />
    </div>
  );
}
