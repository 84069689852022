import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  makeStyles,
} from "@material-ui/core";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function Download({ open, setOpen }) {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          {t("download")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            In the beginning stage, VIETHERB was built in ontology-based
            structure by automatic process using Python programing. The
            ontology-based structure allows us to effectively organize the
            numerous hierarchical multi-class records. The relational database
            was then implemented in Java and MySQL database for homogenous
            querying purposes specifies each attribute for a specific concept.
            Our website shows users only data in the relational database with
            friendly-user interface. Besides, exporting data in OWL - ontology
            format for other specific purpose is supported.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t("cancel")}
          </Button>

          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            <Link
              href={`${process.env.REACT_APP_API_URL}/files/VHO.owl`}
              download
              underline="none"
            >
              {t("download")}
            </Link>
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.vietherb,
  },
}));
