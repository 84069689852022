import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import request from "api/request";

export const useMatchesSize = (size, direction) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints[direction](size));

  return matches;
};

export const useDebouncedQuery = (
  endpoint,
  jsonParams = null,
  onError,
  debounceTime = 200,
  initialCall = false
) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const firstRequested = useRef(false);

  const params = JSON.parse(jsonParams);

  const fetcher = useCallback(async () => {
    try {
      setLoading(true);
      const response = await request.get(endpoint, { params });
      setData(response.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      typeof onError === "function" && onError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, onError, jsonParams]);

  useEffect(() => {
    const debounced = setTimeout(
      async () => {
        if (!initialCall && !firstRequested.current) {
          firstRequested.current = true;
          return;
        }
        firstRequested.current = true;
        fetcher();
      },
      initialCall && !firstRequested.current ? 0 : debounceTime
    );

    return () => {
      clearTimeout(debounced);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher]);

  return { data, loading };
};
