import { useEffect, useState } from "react";
import { useAPI } from "api/api";
import {
  Collapse,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from "@material-ui/core";
import { useHistory } from "react-router";

import { ReactComponent as Leaf } from "assets/images/svg/leaves.svg";
import { ReactComponent as Chemical } from "assets/images/svg/chemical.svg";
import { ReactComponent as Healing } from "assets/images/svg/healing.svg";
import { useTranslation } from "react-i18next";

const searchTypes = {
  EN: "Species EN",
  VI: "Species VN",
  MB: "Metabolites",
  TE: "Therapeutic Effect",
};

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
})(TextField);

export default function Search({ compact = true, inputProps = {}, ...rest }) {
  const api = useAPI();
  const classes = useStyles();
  const history = useHistory();

  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [type, setType] = useState(searchTypes.EN);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function onSearch() {
      if (search || search !== "") {
        if (type === searchTypes.MB) {
          const response = await api.fetcher("get", "metabolite/list", {
            metaboliteName: search,
          });

          setData(response?.data || []);
        } else if (type === searchTypes.TE) {
          const response = await api.fetcher("get", "thera/list", {
            theraName: search,
          });

          setData(response?.data || []);
        } else {
          const searchParam =
            type === searchTypes.EN ? "plantEngName" : "plantVnName";

          const response = await api.fetcher("get", "plant/list", {
            [searchParam]: search,
            order: `${searchParam}|ASC`,
          });

          setData(response?.data || []);
        }
      } else {
        setData([]);
      }
    }

    onSearch();
    // eslint-disable-next-line
  }, [type, search]);

  return (
    <div className={classes.search} {...rest}>
      <CssTextField
        // name="plant"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={t("search")}
        variant="outlined"
        fullWidth
        inputProps={inputProps}
        InputProps={{
          classes: {
            root: classes.outlinedInputStyle,
            input: classes.input,
          },
          endAdornment: (
            <InputAdornment position="end">
              <Select
                value={type}
                onChange={(event) => {
                  setType(event.target.value);
                }}
                variant="outlined"
                classes={{
                  root: classes.rootSelect,
                  select: classes.select,
                  icon: classes.selectIcon,
                }}
                renderValue={(value) => (
                  <span className={classes.type}>
                    {compact
                      ? Object.keys(searchTypes).find(
                          (el) => value === searchTypes[el]
                        )
                      : value}
                  </span>
                )}
                MenuProps={{
                  classes: {
                    paper: classes.paperMenu,
                  },
                }}
              >
                {Object.keys(searchTypes).map((key) => (
                  <MenuItem
                    key={key}
                    className={classes.type}
                    value={searchTypes[key]}
                  >
                    {searchTypes[key]}
                  </MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
        }}
      />

      <Collapse
        in={data?.length > 0}
        timeout="auto"
        classes={{ container: classes.collapse }}
        style={{
          ...(compact && { position: "absolute" }),
          width: "45%",
        }}
      >
        <List component="div" disablePadding>
          {data?.map((el, i) => (
            <ListItem
              button
              key={i}
              onClick={() => {
                setSearch("");
                setData([]);
                if (type === searchTypes.MB) {
                  history.push("/metabolite/" + el.id);
                } else if (type === searchTypes.TE) {
                  history.push("/therapeutic-effects/" + el.id);
                } else {
                  history.push("/species/" + el.id);
                }
              }}
            >
              <ListItemIcon>
                {(type === searchTypes.EN || type === searchTypes.VI) && (
                  <Leaf
                    viewBox="0 0 456.027 456.027"
                    width={32}
                    height={32}
                    fill="#60A917"
                  />
                )}

                {type === searchTypes.MB && (
                  <Chemical
                    viewBox="-17 0 511 511.99936"
                    width={32}
                    height={32}
                    fill="#F0A547"
                  />
                )}

                {type === searchTypes.TE && (
                  <Healing
                    viewBox="-7 0 512 512.0005"
                    width={32}
                    height={32}
                    fill="#D1373F"
                  />
                )}
              </ListItemIcon>
              <ListItemText
                style={{ textTransform: "capitalize", color: "black" }}
                primary={
                  (type === searchTypes.EN && el.plantEngName) ||
                  (type === searchTypes.VI && el.plantVnName) ||
                  (type === searchTypes.MB && el.metaboliteName) ||
                  (type === searchTypes.TE && el.theraName)
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  outlinedInputStyle: {
    paddingRight: 0,
    background: "#FFF",
    borderRadius: 0,
  },
  rootSelect: {
    // width: 44,
    borderRadius: 0,
    padding: 10,
  },
  select: {
    backgroundColor: theme.palette.vietherb_dark,
    "&:focus": {
      backgroundColor: theme.palette.vietherb_dark,
    },
    textAlign: "center",
  },
  selectIcon: {
    color: "#FFF",
  },
  type: {
    color: "#FFF",
    // fontSize: 18,
    fontWeight: 600,
  },
  paperMenu: {
    borderRadius: 0,
    backgroundColor: theme.palette.vietherb,
  },
  search: {
    width: "50%",
    // border: "solid",
    // borderColor: theme.palette.vietherb,
    // borderTop: "none",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    zIndex: 99,
  },
  collapse: {
    backgroundColor: "#FFF",
    borderTop: "1px solid #A0A0A0",
    maxHeight: 400,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 8,
    },

    "&::-webkit-scrollbar-track": {
      background: "#A0A0A0",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#333",
    },
    zIndex: 99,
  },
  input: {
    padding: 10,
  },
}));
