import React from "react";
import "common/i18n";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

import MainRouter from "routes/MainRouter";
import ReactNotification from "react-notifications-component";
import { LightgalleryProvider } from "react-lightgallery";
// import OpenSans from "assets/fonts/OpenSans-Regular.ttf";
//-------------------------------------

// const openSans = {
//   fontFamily: "Open Sans",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `
//     local('OpenSans'),
//     local('OpenSans-Regular'),
//     url(${OpenSans}) format('ttf')
//   `,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

let theme = createMuiTheme({
  typography: {
    // fontFamily: "Open Sans",
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 500,
    },
  },
  palette: {
    vietherb: "rgba(90,167,115,1)",
    vietherb_dark: "rgba(56, 112, 74)",
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       "@font-face": [openSans],
  //     },
  //   },
  // },
});

theme = responsiveFontSizes(theme);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ReactNotification />
      <LightgalleryProvider>
        <MainRouter />
      </LightgalleryProvider>
    </ThemeProvider>
  );
};

export default App;
