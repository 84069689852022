import { lazy } from "react";
import {
  Home,
  GetApp,
  Info,
  PermContactCalendar,
  Public,
} from "@material-ui/icons";
// icon svg
import { ReactComponent as Leaf } from "assets/images/svg/leaves.svg";
import { ReactComponent as Chemical } from "assets/images/svg/chemical.svg";
//-------------------------------------
const Species = lazy(() => import("containers/Species"));
const Metabolite = lazy(() => import("containers/Metabolite"));
const About = lazy(() => import("containers/About"));
const Contact = lazy(() => import("containers/Contact"));
const Index = lazy(() => import("containers/NewHome"));

const routes = [
  {
    path: "/",
    name: "home",
    component: Index,
    icon: (className) => <Home className={className} />,
  },
  {
    path: "/species",
    name: "species",
    component: Species,
    icon: (className) => (
      <Leaf
        viewBox="0 0 456.027 456.027"
        fill="#FFFFFF"
        className={className}
      />
    ),
  },
  {
    path: "/metabolites",
    name: "metabolites",
    component: Metabolite,
    icon: (className) => (
      <Chemical
        viewBox="-17 0 511 511.99936"
        fill="#FFFFFF"
        className={className}
      />
    ),
  },
  {
    path: "#",
    name: "online_prediction",
    component: "",
    icon: (className) => <Public className={className} />,
  },
  {
    path: "#",
    name: "download",
    component: "",
    icon: (className) => <GetApp className={className} />,
  },
  {
    path: "/about",
    name: "about_us",
    component: About,
    icon: (className) => <Info className={className} />,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    icon: (className) => <PermContactCalendar className={className} />,
  },
];

export default routes;
