import { useMemo, forwardRef } from "react";
import clsx from "clsx";
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { NavLink as RouterLink, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  active: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#dfdfdf",
    // fontWeight: 900,
    color: "#dfdfdf",
  },
  button: {
    textTransform: "none",
    letterSpacing: 0,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#5aa773",
    },
  },
  navItem: {
    color: "#000",
    // textTransform: "uppercase",
    width: "auto",
    "&:hover": {
      color: "#dfdfdf",
      transition: theme.transitions.create("all", {
        duration: 600,
      }),
    },
  },
  navText: {
    fontSize: 15,
    fontWeight: 600,
  },
}));

export default function ListItemLink({ to, title, ...rest }) {
  const classes = useStyles();

  const match = useRouteMatch(to);

  const Link = useMemo(
    () =>
      forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem
      button
      component={rest?.button ? null : Link}
      classes={{
        root: classes.navItem,
        button: classes.button,
      }}
      underline="none"
      {...rest}
    >
      <ListItemText
        classes={{
          primary: match?.isExact
            ? clsx(classes.active, classes.navText)
            : classes.navText,
        }}
        primary={title}
      />
    </ListItem>
  );
}
