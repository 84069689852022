/* eslint-disable eqeqeq */
import axios from "axios";
import { store } from "react-notifications-component";
// import i18n from "common/i18n";
// create an axios instance
const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // api base_url
  timeout: 60 * 1000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      // store.addNotification({
      //   message: response.data.message,
      //   type: "success",
      //   insert: "top",
      //   container: "top-right",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 3000,
      //     onScreen: true,
      //   },
      // });
    }
    return response;
  },

  (error) => {
    if (error.code == "ECONNABORTED" || error.response.status === 408) {
      store.addNotification({
        message: "Timeout error",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }

    if (error.response.status === 500) {
      store.addNotification({
        message: "System error",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } else if (error.response.status === 401) {
      store.addNotification({
        message: "Unauthorize error",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } else if (error.response.status === 404) {
      const mess = error.response.data.message;
      if (mess) {
        store.addNotification({
          message: mess,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      }
    } else {
      const mess = error.response.data.message;
      if (mess) {
        store.addNotification({
          message: mess,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      }
    }

    return Promise.reject(error);
  }
);

export default service;
