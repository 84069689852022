import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "i18n/en_US.json";
import vi from "i18n/vi_VN.json";

import { ReactComponent as usFlag } from "assets/images/svg/us.svg";
import { ReactComponent as viFlag } from "assets/images/svg/vi.svg";
//-------------------------------------

export const languagesLabel = [
  {
    code: "en-US",
    text: "English",
    flag: {
      svg: usFlag,
      viewBox: "0 0 7410 3900",
    },
  },
  {
    code: "vi-VN",
    text: "Tiếng Việt",
    flag: {
      svg: viFlag,
      viewBox: "0 0 30 20",
    },
  },
];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    // lng: "en-US",
    fallbackLng: "vi",
    resources: {
      en: {
        translation: en,
      },
      vi: {
        translation: vi,
      },
    },
  });

export default i18n;
